@import 'css/core.scss';
@import 'css/measurements.scss';
@import 'css/colors.scss';
@import 'css/utils.scss';

.ant-table-cell {
  .actions {
    text-align: right;
    .btn {
      font-size: 14px;
      line-height:16px;
      height: auto;
      padding:5px 5px;
    }
  }
}

.btn {
  &.btn-green {
    background-color: #45a95b;
    border-color: #45a95b;
    color: #fff;
  }
}

.ant-breadcrumb {
  font-size: 18px !important;
}

.action-column {
  display: flex;
  flex-direction: row;
}
