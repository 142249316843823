@import 'css/layout.scss';
@import 'css/utils.scss';
@import 'css/router.scss';

.ant-picker {
  width: 100%;
}

.ant-table-cell {
  &.hidden {
    display: none !important;
  }
}

.ant-table-wrapper {
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.125);

  .ant-input-number {
    width: 100% !important;
  }

  .ant-table-cell {
    .ant-select {
      width: 100%;
    }
  }
}
