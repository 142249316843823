@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/nprogress/style';
@import 'components/kit/vendors/chartist/style';
@import 'components/kit/vendors/chartist-tooltip-plugin/style';
@import 'components/kit/vendors/jvectormap/style';
@import 'components/kit/vendors/react-sortable-tree/style';
@import 'components/kit/vendors/react-draft-wysiwyg/style';

@import 'components/kit/core/style';
// Clean UI KIT styles
@import 'components/cleanui/styles/style'; // Clean UI styles

$primary-color: #212121;

$separator-color-light: #f3f3f3;
$theme-color-1: #d6668a;
$foreground-color: white;
$theme-color-2: #e7284a;
$breakpoints: (
  xxs: 420px,
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px,
);

@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint); // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

.big-calendar-header {
  margin-bottom: 1em;
}

.rbc-month-header {
  min-height: 50px;
}

.rbc-today {
  background: initial;
}

.calendar-prev-btn,
.calendar-next-btn {
  outline: initial !important;
  box-shadow: initial !important;
  border-radius: 40px !important;
  text-align: center;
  min-width: 30px;
  height: 30px;
  padding: 0.55rem 0;
  background: $theme-color-1;
  color: $foreground-color;
  border: 1px solid $theme-color-1;
  line-height: 0.9 !important;
  font-size: 0.76rem;
  font-weight: normal !important;

  span {
    line-height: 1 !important;
    font-size: 0.76rem;
    font-weight: normal !important;
  }

  &:hover {
    background-color: transparent;
    border-color: lighten($theme-color-1, 10%);
    color: $theme-color-1;
  }
}

.calendar-prev-btn {
  margin-right: 5px;
}

.calendar-today-btn {
  padding: 0.4em 1.3em !important;
  height: unset !important;
}

.rbc-month-row {
  min-height: 5em;
  z-index: 1;

  @include respond-below(xs) {
    min-height: 3em;
  }
}

.rbc-month-view {
  border: initial;
}

.rbc-off-range-bg {
  background: initial;
}

.rbc-off-range {
  color: $primary-color;
  opacity: 0.3;
}

.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row,
.rbc-header + .rbc-header,
.rbc-header {
  border-color: $separator-color-light !important;
}

.rbc-header {
  padding: 15px 5px;
  color: $theme-color-1;
}

.rbc-date-cell {
  padding: 10px;

  a {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    font-weight: initial;
    display: inline-block;
  }
}

.rbc-date-cell.rbc-now a {
  background: $theme-color-1;
  color: $foreground-color;
}

.rbc-event {
  font-size: 0.85em;
  border-radius: 25px;
  text-align: center;
  padding: 0 5px;
  background: $theme-color-2;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-strikethrough {
  text-decoration: line-through;
}
