// Import mixins
@import 'components/mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-table-column-has-sorters {
    &:hover {
      background: $dark-gray-5 !important;
    }
  }

  .ant-table-column-sort {
    background: $dark-gray-5 !important;
  }
}

[data-kit-theme='default'] {
  .ant-table-pagination {
    &.ant-pagination {
      margin: 16px;
    }
  }
}

.ant-table {
  .ant-table-tbody {
    .ant-table-cell {
      &.actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
