.container {
  :global(.progress-wrap) {
    display: flex;
    justify-content: center;

    > div:nth-child(1) {
      flex-shrink: 0;
      padding-right: 10px;
      line-height: 22px;
      font-weight: bold;
    }
  }

  :global(.info) {
    display: flex;
    align-items: center;

    :global(.progress-wrap) {
      flex-grow: 1;
    }
    :global(.btn) {
      margin-left: 20px;
      flex-shrink: 0;
      flex-grow: 0;
      width: 120px;
    }

    @media (max-width: 768px) {
      flex-direction: column;

      :global(.progress-wrap) {
        width: 100%;
      }

      :global(.btn) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}
